import { useI18n } from 'vue-i18n';
import type { ComputedRef } from 'vue';
import type { Breadcrumb } from '@shopware-pwa/composables-next';
import criteria from '~/composables/criteria/usePageApiParams.json';
import { useCmsStore } from '~/composables/useCmsStore';
import { ApiClientError } from '@shopware/api-client';
import { AxiosError } from 'axios';
import type { RequestReturnType } from '#shopware';
import type { AhProduct } from '~/types/models/product/product';
import type { AhBreadcrumb } from '~/types/models/page';

export type UsePageReturn = {
    getPathWithoutLang: (path: string) => string;
    getPage: (path: string) => Promise<RequestReturnType<'pwaResolvePage'> | undefined>;
    page: ComputedRef<RequestReturnType<'pwaResolvePage'> | null>;
    product: ComputedRef<AhProduct | null>;
    pageBreadcrumbs: ComputedRef<Breadcrumb[]>;
};

/*
uses custom /store-api/pwa/page endpoint to get cms page from a seo url with only 1 request
(instead of 2 requests to /store-api/seo-url and /store-api/category/xy)
 */
export function usePage(): UsePageReturn {
    const { apiClient } = useShopwareContext();
    const { updateSeoUrlForeignKey, updateProduct } = useCmsStore();

    const { query } = useRoute();

    const { locales } = useI18n();
    const localeCodes = computed(() => locales.value.map((locale) => locale.code));

    const _storePage = useState<RequestReturnType<'pwaResolvePage'> | null>('page', () => ref(null));
    const product = computed(() => _storePage.value?.product as AhProduct | null);

    const getPathWithoutLang = (path: string): string => {
        if (localeCodes.value?.length) {
            const localeRegExp = new RegExp(`^/(${localeCodes.value.join('|')})(/|$)`);

            path = path.replace(localeRegExp, '');
        }

        const isTechnicalUrl =
            path.startsWith('navigation/') || path.startsWith('detail/') || path.startsWith('landingPage/');

        if (isTechnicalUrl) {
            path = '/' + path; // technical url needs slash at the beginning
        }

        return path;
    };

    const getPage = async (path: string): Promise<RequestReturnType<'pwaResolvePage'> | undefined> => {
        const categoryId = query.c as string | undefined;
        try {
            const response = await apiClient.invoke('pwaResolvePage post /pwa/page sw-include-seo-urls', {
                ...criteria,
                path,
                'sw-include-seo-urls': true,
                ...(categoryId ? { c: categoryId } : {}),
            });

            if (path === '' && response?.breadcrumbs?.length) {
                response.breadcrumbs = [];
            }

            _storePage.value = response;

            if (_storePage.value?.resourceIdentifier) updateSeoUrlForeignKey(_storePage.value.resourceIdentifier);
            if (_storePage.value?.product) updateProduct(_storePage.value.product);

            return _storePage.value;
        } catch (error) {
            if (error instanceof ApiClientError) {
                throw createError({ statusCode: error.status, statusMessage: error.message });
            } else if (error instanceof AxiosError) {
                throw createError({ statusCode: parseInt(error.code || ''), statusMessage: error.message });
            } else if (error instanceof Error) {
                throw error;
            }
        }
    };

    const pageBreadcrumbs = computed<AhBreadcrumb[]>(
        () =>
            _storePage.value?.breadcrumbs?.map(
                ({ name, path, seoPath }) => ({ name, path, seoPath }) as AhBreadcrumb,
            ) || [],
    );

    return {
        getPathWithoutLang,
        getPage,
        page: computed(() => _storePage.value),
        product,
        pageBreadcrumbs,
    };
}
